import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/proxies/",
  "date": "2015-07-27",
  "title": "PROXIES",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Proxies enable you to intercept and customize actions performed on JavaScript objects such as `getter` and `setter` for getting and setting properties. This is really helpful in scenarios like JSON based API interaction where you have to customize data coming back from the API."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`They introduce `}<inlineCode parentName="p">{`intercession`}</inlineCode>{` to JavaScript, which is a type of `}<inlineCode parentName="p">{`meta`}</inlineCode>{` programming.`}</p>
    <Message type="info" title="Meta Programming" content="In programming there different levels, `base level` which perform user input and `meta level` which processes base level code. An example is `eval()` which compiles JavaScript code, which itself is JavaScript. But you can use two different languages for base level and meta level. Or just JavaScript." mdxType="Message" />
    <p>{`Creating a proxy on a particular object allows a predefined handler to get notified when something changes on that object.`}</p>
    <p>{`Proxies are created with two parameters `}<inlineCode parentName="p">{`handler`}</inlineCode>{` and `}<inlineCode parentName="p">{`target`}</inlineCode>{`.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`handler`}</strong>{` - Intercepts the operation on the way to target.`}</li>
      <li parentName="ul"><strong parentName="li">{`target`}</strong>{` - If the `}<inlineCode parentName="li">{`handler`}</inlineCode>{` doesn't intercept an operation then it's performed on the target.`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`// Proxying a normal object
var target = {};
var handler = {
  get: function (receiver, name) {
    return 'Hello, ' + name;
  }
};

var p = new Proxy(target, handler);
p.world === "Hello, world!";

// Proxying a function object
var target = function () { return "I am the target"; };
var handler = {
  apply: function (receiver, ...args) {
    return "I am the proxy";
  }
};

var p = new Proxy(target, handler);
p() === "I am the proxy";
`}</code></pre>
    <h2>{`Methods of handler object`}</h2>
    <p>{`The handler object is a placeholder object which contains traps for Proxy.`}</p>
    <Message type="info" title="Traps" content="All traps are optional. If a trap has not been defined, the default behavior is to forward the operation to the target." mdxType="Message" />
    <p>{`Here are the methods of handler object:`}</p>
    <ul>
      <li parentName="ul">{`getPrototypeOf()`}</li>
      <li parentName="ul">{`setPrototypeOf()`}</li>
      <li parentName="ul">{`isExtensible()`}</li>
      <li parentName="ul">{`preventExtensions()`}</li>
      <li parentName="ul">{`getOwnPropertyDescriptor()`}</li>
      <li parentName="ul">{`defineProperty()`}</li>
      <li parentName="ul">{`has()`}</li>
      <li parentName="ul">{`get()`}</li>
      <li parentName="ul">{`set()`}</li>
      <li parentName="ul">{`deleteProperty()`}</li>
      <li parentName="ul">{`enumerate()`}</li>
      <li parentName="ul">{`ownKeys()`}</li>
      <li parentName="ul">{`apply()`}</li>
      <li parentName="ul">{`apply()`}</li>
    </ul>
    <p>{`Here's another example that sets a property using proxy.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var student = { name: 'John Smith', age: 16 };

var interceptor = {
  set: function (receiver, property, value) {
    console.log(property, 'is changed to', value);
    receiver[property] = value;
  }
};

student = Proxy(student, interceptor);

student.age = 17; // age is changed to 17
`}</code></pre>
    <p>{`Here the `}<inlineCode parentName="p">{`intercepter`}</inlineCode>{` is the handler, which is the second parameter. The `}<inlineCode parentName="p">{`student`}</inlineCode>{` object has been replaced by the another one as the result of installing proxy via `}<inlineCode parentName="p">{`Proxy()`}</inlineCode></p>
    <p>{`Beside for debugging purposes, proxy can be helpful for libraries which implement data binding. Because a handler can be hooked to the data model, there is no need to use an alternative syntax (e.g. explicit set to change a value) or to continuously track the change (e.g. dirty state) to modify the model.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      